<template>
    <div class="container w-full h-full">
        <SurveyVisitPreferredDays />
    </div>
</template>

<script>
import SurveyVisitPreferredDays from '@/components/survey-visit/SurveyVisitPreferredDays.vue'
export default {
    name: 'PreferredDays',
    components: {
        SurveyVisitPreferredDays
    }
}
</script>
