<template>
    <div class="flex flex-col w-9/12 h-full m-auto">
        <div v-if="errorCode !== 500">
            <h1 class="text-black text-lg lg:text-2xl text-center mb-3 mt-4">Set Practice Availability</h1>
            <p class="text-sm text-grey-1 text-center">Your survey visit for {{practiceName}} is due soon.</p>
            <p class="text-sm text-grey-1 text-center">To progress your booking please submit your availability.</p>
        </div>
        <v-form 
            class="flex flex-col justify-around w-full h-full"
            v-if="loaded"
        >
            <div class="mt-4">
                <h2 class="text-black text-base mb-1 font-bold">Choose 3 weeks</h2>
                <p class="text-grey-1 text-sm font-normal">Out of the 4 weeks bellow please <span class="font-semibold">select 3 weeks</span> you are available for survey visits</p>
                <div><p ref="week" class="hidden">Please select 3 weeks.</p></div>
                <div class="flex flex-row mt-4">
                    <div
                        v-for="(item, index) in allowedWeeks"
                        :key="index"
                        class="flex flex-row align-center ml-2 mr-6"
                    >
                        <v-checkbox :ripple="false" :value="weeks[index]" v-model="formData.preferred_weeks"></v-checkbox>
                        <span class="pb-1">{{item}}</span>
                    </div>
                </div>
            </div>
            <div>
                <h2 class="text-black text-base mb-1 font-bold">Available days of the week</h2>
                <p class="text-grey-1 text-sm font-normal">Select at least <span class="font-semibold">3 days of the week</span> you are available for survey visits. If the day is greyed out it means there are no surveyors available for this day</p>
                <p class="hidden" ref="day">Select at least 3 days of the week.</p>
                <div class="flex flex-row mt-4">
                    <div 
                        v-for="(item, index) in days"
                        :key="index"
                    >
                        <div :class="item.class">
                            <v-checkbox :ripple="false" :value="item.day" v-model="formData.preferred_days_of_week" :disabled="item.disable"></v-checkbox>
                            <span class="pb-1">{{item.day}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 class="text-black text-base mb-1 font-bold">Internet access</h2>
                <p class="text-grey-1 text-sm font-normal">Will there be a wireless network available for the surveyors to have internet access?</p>
                <div class="flex flex-row align-center ml-2 mt-4">
                    <v-checkbox :ripple="false" v-model="formData.has_wifi_for_surveyors"></v-checkbox>
                    <span class="pb-1">Yes there will be wireless internet access for the surveyors</span>
                </div>
            </div>
            <div class="text-center">
                <v-btn
                    color="primary"
                    depressed
                    @click="submitFormData()"
                >
                    <span>Submit My Availability</span>
                </v-btn>
            </div>
        </v-form>
        <div 
            v-if="!loaded"
            class="text-center"
        >
            <h1 class="text-black text-lg lg:text-2xl text-center mb-3 mt-4">Unable to load this page!
            </h1>
            <p>Please try again by refreshing or contact your system administrator.</p>
            <img src="@/assets/images/error.png" class="max-w-500 m-auto" :style="imgStyle" alt="Error" />
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import MessageDialog from '@/components/shared/mixins/messageDialog'

export default {
    name: 'SurveyVisitPreferredDays',
    mixins: [MessageDialog],
    data() {
        return {
            loaded: true,
            errorCode: null,
            allowedDays: [],
            allowedWeeks: [],
            weeks: [],
            practiceName: "",
            days: [
                {
                    day: "Monday",
                    class: "flex flex-row align-center ml-2 mr-8 opacity-25",
                    disable: true
                },
                {
                    day: "Tuesday",
                    class: "flex flex-row align-center ml-2 mr-8 opacity-25",
                    disable: true
                },
                {
                    day: "Wednesday",
                    class: "flex flex-row align-center ml-2 mr-8 opacity-25",
                    disable: true
                },
                {
                    day: "Thursday",
                    class: "flex flex-row align-center ml-2 mr-8 opacity-25",
                    disable: true
                },
                {
                    day: "Friday",
                    class: "flex flex-row align-center ml-2 mr-8 opacity-25",
                    disable: true
                }
            ],
            duration: 3000,
            formData: {
                preferred_days_of_week: [],
                preferred_weeks: [],
                has_wifi_for_surveyors: false
            }
        }
    },
    mounted() {
        axios
            .get(`web.surveyvisit/${this.$route.params.id}/allowed_preferred_days/`)
            .then(response => {
                this.allowedDays = response.data.preferred_days_of_week
                this.weeks = response.data.preferred_weeks
                this.practiceName = response.data.practice_name
                this.setClass(this.allowedDays)
                this.setAllowedWeeks(this.weeks)
            })
            .catch(error => {
                const errorStatus = Math.floor(error.response.status/100)
                if(errorStatus === 4) {
                    this.message = `Error: Request failed with status code ${error.response.status}`
                    this.type = 'error'
                    setTimeout(() => {
                        this.$router.push({name: "PracticeList"})
                    }, 2000)
                }
                if(error.response.status === 500) {
                    this.errorCode = 500
                    this.loaded = false
                    this.message = 'Error: This page could not be loaded. Please contact your System Administrator.'
                    this.type = 'error'
                }
                this.showMessage({duration: this.duration})
            })
    },
    methods: {
        setClass(allowedDays) {
            this.days.map(item => {
                allowedDays.map(i => {
                    if(i === item.day) {
                        item.class = "flex flex-row align-center ml-2 mr-8"
                        item.disable = false
                    }
                })
            })
        },
        setAllowedWeeks(weeks) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            weeks.map(item => {
                const date1 = new Date(item[0])
                const date2 = new Date(item[1])
                this.allowedWeeks.push(
                    `${item[0].slice(-2)} ${months[date1.getMonth()]} - ${item[1].slice(-2)} ${months[date2.getMonth()]} ${date2.getFullYear()}`
                )
            })
        },
        validate(weeks, days) {
            weeks.length < 3
                ? (this.$refs.week.classList.value = ["text-red text-sm font-normal"])
                : (this.$refs.week.classList.value = "hidden")
            days.length < 3
                ? (this.$refs.day.classList.value = ["text-red text-sm font-normal"])
                : (this.$refs.day.classList.value = "hidden")
        },
        submitFormData() {
            const formWeeks = this.formData.preferred_weeks
            const formDays = this.formData.preferred_days_of_week
            this.validate(formWeeks, formDays)
            if(formWeeks.length >= 3 && formDays.length >=3) {
                axios
                    .post(`web.surveyvisit/${this.$route.params.id}/set_preferred_days/`, this.formData)
                    .then(response => {
                        this.message = 'Your preferred days have been saved.'
                        this.type = 'success'
                        setTimeout(() => {
                            this.$router.push({name: "Staff", params: {id: response.data.practice_id}})
                        }, 3000)
                    })
                    .catch(error => {
                        this.message = `Error: Request failed with status code ${error.response.status}`
                        this.type = 'error'
                    })
                    .finally(() => {
                        this.showMessage({duration: this.duration})
                    })
            }
        }
    }
}
</script>
